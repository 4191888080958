<template>
  <v-card>
    <v-card-title class="headline-2">{{ title }}</v-card-title>

    <v-card-text>
      <slot></slot>
    </v-card-text>

    <v-card-actions class="pb-3">
      <v-spacer></v-spacer>
      <v-btn small color="txt-dark darken-1" text @click="$emit('cancel')">{{
        cancelLabel
      }}</v-btn>
      <v-btn small dark @click="$emit('ok')">{{ okLabel }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Are you sure?'
    },
    cancelLabel: {
      type: String,
      default: 'No'
    },
    okLabel: {
      type: String,
      default: 'Yes'
    }
  }
}
</script>
