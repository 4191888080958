import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"headline-2"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._t("default")],2),_c(VCardActions,{staticClass:"pb-3"},[_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"txt-dark darken-1","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.cancelLabel))]),_c(VBtn,{attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.$emit('ok')}}},[_vm._v(_vm._s(_vm.okLabel))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }